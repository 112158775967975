var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.role ? 'container_is_auth' : 'container_no_auth'},[(!_vm.loading)?_c('div',{class:_vm.role ? 'child_is_auth' : 'child_no_auth'},[(!_vm.role)?_c('Nav'):_vm._e(),(_vm.ebook)?_c('div',_vm._l((_vm.ebook.data),function(item){return _c('v-card',{key:item.id,staticClass:"radius-card width__thread"},[(item.image)?_c('v-img',{staticClass:"img-fit bottom-gradient",attrs:{"src":(_vm.env + "/ebook/upload/" + (item.user_id) + "/image/" + (item.image)),"height":"300px"}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('div',[_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"color":item.bidang_id == 1
                    ? 'a__'
                    : item.bidang_id == 2
                    ? 'c__'
                    : 'b__'}},[_vm._v(" "+_vm._s(item.nama)+" ")]),(item.status_blokir == 0 && _vm.role == 1)?_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.block(item, 1)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-checkbox-marked-circle")]),_vm._v(" block")],1):_vm._e(),(item.status_blokir == 1 && _vm.role == 1)?_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.unblock(item, 0)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-checkbox-marked-circle")]),_vm._v(" unblock")],1):_vm._e()],1),_c('section',{staticClass:"px-3"},[_c('h3',[_vm._v(_vm._s(item.judul))]),_c('p',[_vm._v("By "+_vm._s(item.author))])])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(item.image)?_c('v-img',{staticClass:"img-fit overflow__img",attrs:{"src":(_vm.env + "/ebook/upload/" + (item.user_id) + "/image/" + (item.image))}}):_vm._e(),_c('a',{staticClass:"__reset_decor",attrs:{"href":(_vm.env + "/ebook/upload/" + (item.user_id) + "/file/" + (item.file))}},[_c('div',{staticClass:"__btn__dwnld"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"green","outlined":"","rounded":""}},[_vm._v("Download Ebook")])],1)])],1)],1),_c('article',{staticClass:"px-5",domProps:{"innerHTML":_vm._s(item.synopsis)}}),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"d-flex align-center pa-3"},[_c('v-avatar',{staticClass:"mr-3"},[_c('img',{staticClass:"img-fit",attrs:{"src":item.photo_profile
                    ? (_vm.env + "/upload/photo_profile/" + (item.user_id) + "/" + (item.photo_profile))
                    : (_vm.env + "/images/logo.png"),"alt":""}})]),_c('div',[_c('h4',[_vm._v(_vm._s(item.nama_lengkap))])])],1),_c('v-spacer'),_c('div',[_c('p',{staticClass:"ma-0 grey--text"},[(!item.relationships[0].like.isLike)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100
                }),expression:"{\n                  arrow: true,\n                  arrowType: 'round',\n                  animation: 'fade',\n                  theme: 'light',\n                  maxWidth: 100\n                }"}],attrs:{"text":"","color":"blue","content":"like"},on:{"click":function($event){return _vm.like(item.id)}}},[_c('v-icon',[_vm._v("mdi-heart")]),_c('span',[_vm._v(_vm._s(item.relationships[0].like.data))])],1):_vm._e(),(item.relationships[0].like.isLike)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100
                }),expression:"{\n                  arrow: true,\n                  arrowType: 'round',\n                  animation: 'fade',\n                  theme: 'light',\n                  maxWidth: 100\n                }"}],attrs:{"text":"","color":"red","content":"like"},on:{"click":function($event){return _vm.unlike(item.id)}}},[_c('v-icon',[_vm._v("mdi-heart")]),_c('span',[_vm._v(_vm._s(item.relationships[0].like.data))])],1):_vm._e()],1),_c('p',{staticClass:"ma-0 grey--text"},[_vm._v(" Posted "+_vm._s(_vm.$date(item.created_at).fromNow())+" ")])])],1)],1)}),1):_vm._e(),_c('div',[_c('v-card',{staticClass:"radius-card pa-3 mt-3"},[(!_vm.comment)?_c('div',{staticClass:"d-flex algn-center justify-center flex-column"},[_c('img',{attrs:{"src":require("../../assets/img/404.svg"),"height":"100px","alt":"404"}}),_c('h4',{staticClass:"text-center"},[_vm._v("No Comment")])]):_c('div',{staticClass:"pa-3"},_vm._l((_vm.comment.data),function(comments,idx){return _c('v-card',{key:idx,staticClass:"radius-card pa-3 mb-2"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"mr-3"},[_c('img',{staticClass:"img-fit",attrs:{"src":comments.relationships[0].user.data.photo_profile
                        ? (_vm.env + "/upload/photo_profile/" + (comments.user_id) + "/" + (comments.relationships[0].user.data.photo_profile))
                        : (_vm.env + "/images/logo.png"),"alt":""}})]),_c('div',{staticClass:"d-flex align-center"},[_c('h4',[_vm._v(_vm._s(comments.relationships[0].user.data.username))]),_c('span',{staticClass:"mx-1"},[_c('b',[_vm._v(".")])]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$date(comments.created_at).fromNow()))])])],1),(comments.user_id == _vm.id)?_c('div',[_c('v-btn',{attrs:{"icon":"","small":"","color":"blue"},on:{"click":function($event){return _vm.editComment(comments)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.delComment(comments.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]),_c('div',{staticClass:"mt-2"},[_c('p',[_vm._v(_vm._s(comments.content))])])])}),1),(_vm.role)?_c('div',{staticClass:"d-flex flex-column pa-3"},[_c('v-textarea',{attrs:{"name":"comment","id":"fieldComment","hide-details":"","label":"Write something!","auto-grow":"","solo":""},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-3 blue",attrs:{"dark":""},on:{"click":function($event){return _vm.commented(_vm.ebook)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tooltip-check")]),_vm._v("Comment")],1)],1),_c('div',{attrs:{"id":"comment"}})],1):_vm._e()])],1)],1):_vm._e(),(_vm.loading)?_c('div',{class:_vm.role ? 'child_is_auth' : 'child_no_auth'},[_c('v-skeleton-loader',{attrs:{"type":"card-avatar, article, actions"}})],1):_vm._e(),_c('div',{staticClass:"pa-3"},[_c('EditCommentLibrary',{attrs:{"dialogComment":_vm.dialogComment,"dataComment":_vm.dataComment},on:{"close":_vm.closeDialog,"fetch":_vm.fetchComment}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }