<template>
  <div :class="role ? 'container_is_auth' : 'container_no_auth'">
    <div :class="role ? 'child_is_auth' : 'child_no_auth'" v-if="!loading">
      <Nav v-if="!role" />
      <div v-if="ebook">
        <v-card
          class="radius-card width__thread"
          v-for="item in ebook.data"
          :key="item.id"
        >
          <v-img
            v-if="item.image"
            :src="`${env}/ebook/upload/${item.user_id}/image/${item.image}`"
            height="300px"
            class="img-fit bottom-gradient"
          ></v-img>

          <v-row>
            <v-col cols="12" md="9">
              <div>
                <v-chip
                  class="ma-2 white--text"
                  :color="
                    item.bidang_id == 1
                      ? 'a__'
                      : item.bidang_id == 2
                      ? 'c__'
                      : 'b__'
                  "
                >
                  {{ item.nama }}
                </v-chip>
                <v-btn
                  v-if="item.status_blokir == 0 && role == 1"
                  color="red"
                  dark
                  @click="block(item, 1)"
                  ><v-icon class="mr-1">mdi-checkbox-marked-circle</v-icon>
                  block</v-btn
                >
                <v-btn
                  v-if="item.status_blokir == 1 && role == 1"
                  color="green"
                  dark
                  @click="unblock(item, 0)"
                  ><v-icon class="mr-1">mdi-checkbox-marked-circle</v-icon>
                  unblock</v-btn
                >
              </div>
              <section class="px-3">
                <h3>{{ item.judul }}</h3>
                <p>By {{ item.author }}</p>
              </section>
            </v-col>
            <v-col cols="12" md="3">
              <v-img
                v-if="item.image"
                :src="`${env}/ebook/upload/${item.user_id}/image/${item.image}`"
                class="img-fit overflow__img"
              ></v-img>
              <a
                :href="`${env}/ebook/upload/${item.user_id}/file/${item.file}`"
                class="__reset_decor"
              >
                <div class="__btn__dwnld">
                  <v-btn color="green" class="mt-3" outlined rounded
                    >Download Ebook</v-btn
                  >
                </div>
              </a>
            </v-col>
          </v-row>
          <article class="px-5" v-html="item.synopsis"></article>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="d-flex align-center pa-3">
              <v-avatar class="mr-3">
                <img
                  :src="
                    item.photo_profile
                      ? `${env}/upload/photo_profile/${item.user_id}/${item.photo_profile}`
                      : `${env}/images/logo.png`
                  "
                  class="img-fit"
                  alt=""
                />
              </v-avatar>
              <div>
                <h4>{{ item.nama_lengkap }}</h4>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div>
              <p class="ma-0 grey--text">
                <v-btn
                  text
                  color="blue"
                  @click="like(item.id)"
                  content="like"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  v-if="!item.relationships[0].like.isLike"
                >
                  <v-icon>mdi-heart</v-icon>
                  <span>{{ item.relationships[0].like.data }}</span>
                </v-btn>
                <v-btn
                  text
                  color="red"
                  @click="unlike(item.id)"
                  content="like"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  v-if="item.relationships[0].like.isLike"
                >
                  <v-icon>mdi-heart</v-icon>
                  <span>{{ item.relationships[0].like.data }}</span>
                </v-btn>
              </p>
              <p class="ma-0 grey--text">
                Posted {{ $date(item.created_at).fromNow() }}
              </p>
            </div>
          </v-card-actions>
        </v-card>
      </div>
      <!-- komentar -->
      <div>
        <v-card class="radius-card pa-3 mt-3">
          <div
            class="d-flex algn-center justify-center flex-column"
            v-if="!comment"
          >
            <img src="../../assets/img/404.svg" height="100px" alt="404" />
            <h4 class="text-center">No Comment</h4>
          </div>
          <div class="pa-3" v-else>
            <v-card
              class="radius-card pa-3 mb-2"
              v-for="(comments, idx) in comment.data"
              :key="idx"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-avatar class="mr-3"
                    ><img
                      :src="
                        comments.relationships[0].user.data.photo_profile
                          ? `${env}/upload/photo_profile/${comments.user_id}/${comments.relationships[0].user.data.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                      class="img-fit"
                      alt=""
                  /></v-avatar>
                  <div class="d-flex align-center">
                    <h4>{{ comments.relationships[0].user.data.username }}</h4>
                    <span class="mx-1"><b>.</b></span>
                    <small class="grey--text">{{
                      $date(comments.created_at).fromNow()
                    }}</small>
                  </div>
                </div>
                <div v-if="comments.user_id == id">
                  <v-btn icon small color="blue" @click="editComment(comments)"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn icon color="blue" @click="delComment(comments.id)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </div>
              </div>
              <div class="mt-2">
                <p>{{ comments.content }}</p>
              </div>
            </v-card>
          </div>
          <div class="d-flex flex-column pa-3" v-if="role">
            <v-textarea
              name="comment"
              id="fieldComment"
              hide-details
              v-model="content"
              label="Write something!"
              auto-grow
              solo
            ></v-textarea>
            <div>
              <v-spacer></v-spacer>
              <v-btn class="mt-3 blue" dark @click="commented(ebook)"
                ><v-icon class="mr-2">mdi-tooltip-check</v-icon>Comment</v-btn
              >
            </div>
            <div id="comment"></div>
          </div>
        </v-card>
      </div>
    </div>
    <div :class="role ? 'child_is_auth' : 'child_no_auth'" v-if="loading">
      <v-skeleton-loader
        type="card-avatar, article, actions"
      ></v-skeleton-loader>
    </div>
    <div class="pa-3">
      <EditCommentLibrary
        v-bind:dialogComment="dialogComment"
        v-bind:dataComment="dataComment"
        @close="closeDialog"
        @fetch="fetchComment"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import EditCommentLibrary from "../../components/Psycholog/Modal/editCommentLibrary.vue";
import Nav from "../../components/General/Nav.vue";

export default {
  components: { EditCommentLibrary, Nav },
  name: "viewEbook",
  component: {
    EditCommentLibrary
  },
  computed: {
    ...mapState({
      ebook: state => state.library.e_bookDetail,
      comment: state => state.library.ebookComment,
      env: state => state.API_URL,
      role: state => state.role,
      id: state => state.id
    })
  },
  data() {
    return {
      loading: false,
      content: "",
      dataComment: null,
      dialogComment: false
    };
  },
  mounted() {
    this.fetch();
    // this.fetchComment();
  },
  methods: {
    fetch(like) {
      if (!like) {
        this.loading = true;
      }
      let id = this.$route.params.id;
      if (this.$route.params.visible == "private") {
        this.$store.dispatch("library/viewPrivateEbook", id).then(() => {
          this.loading = false;
        });
      } else {
        this.$store.dispatch("library/ebookById", id).then(() => {
          this.loading = false;
        });
      }
    },
    fetchComment() {
      let id = this.$route.params.id;
      this.$store.dispatch("library/listCommentEbook", id);
    },
    commented(item) {
      let data = {
        commentable_id: item.data[0].id,
        content: this.content
      };
      this.$store.dispatch("library/createCommentEbook", data).then(() => {
        this.content = "";
        this.fetchComment();
      });
    },
    editComment(data) {
      this.dataComment = data;
      this.dialogComment = true;
    },
    closeDialog() {
      this.dataComment = null;
      this.dialogComment = false;
    },
    delComment(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("library/deleteCommentArticle", id).then(() => {
            this.fetchComment();
            Swal.fire({
              icon: "success",
              title: "Komentar terhapus!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000
            });
          });
        }
      });
    },

    like(id) {
      if (this.role) {
        this.$store.dispatch("library/likeEbook", id).then(() => {
          this.fetch(true);
        });
      } else {
        console.log(
          "%c farida hayu pramesthi",
          "font-size:20px; color:white; background:pink;"
        );
      }
    },

    unlike(id) {
      this.$store.dispatch("library/unlikeEbook", id).then(() => {
        this.fetch(true);
      });
    },

    block(item, status_blokir) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(() => {
        Swal.fire({
          title: "Masukan alasan pemblokiran",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
          if (result.isConfirmed) {
            let data = {
              item: item,
              status_blokir: status_blokir,
              alasan_blokir: result.value
            };
            console.log("result", result);
            console.log("data", data);
            this.$store
              .dispatch("library/statusBlockEbook", data)
              .then(response => {
                console.log("response", response);
                if (response.status != "success") {
                  throw new Error(response);
                }
                this.fetch(true);
                Swal.fire({
                  title: `Ebook telah diblokir karena ${result.value}`
                });
              })
              .catch(error => {
                Swal.showValidationMessage(`Permintaan gagal: ${error}`);
              });
          }
        });
      });
    },

    unblock(item, status_blokir, alasan_blokir = "") {
      let data = {
        item: item,
        status_blokir: status_blokir,
        alasan_blokir: alasan_blokir
      };
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("library/statusBlockEbook", data).then(() => {
            this.fetch(true);
            Swal.fire({
              icon: "success",
              title: "Ebook diunblock!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.overflow__img {
  width: 200px;
  height: 250px;
  margin-top: -150px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.29);
}
.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  width: 100%;
}
.child_no_auth {
  margin-top: 10px;
  padding: 10px;
  margin: 0 auto;
  width: 80%;
  margin-top: 100px;
}
div >>> img {
  /* height: 200px; */
  width: 100%;
}
.__reset_decor {
  text-decoration: none;
}

@media (max-width: 576px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
  .overflow__img {
    margin-top: 0;
    margin: 0 auto;
  }
  .child_no_auth {
    width: 100%;
  }
  .__btn__dwnld {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .__btn__dwnld {
    display: flex;
    justify-content: center;
  }
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
  .overflow__img {
    margin-top: 0;
    margin: 0 auto;
  }
  .child_no_auth {
    width: 100%;
  }
}
</style>
